import React from 'react'
import Navigation from '../components/navigation';
import Logo from "../images/logo-white.svg";
export default function index() {
  return (
    <div> 
      <Navigation pageTitle="Home" />
      <div className="flex">

        <div className="mx-auto mt-5">
          {/* spin animation on img */}
          <img src={Logo} alt="logo" className="animate-slow-spin" />
        </div>
      </div>
      <div className="p-5 items-center max-w-2xl mx-auto mt-5">
        <p className="text-center mb-4">
          Reformed Akwaaja Ahu Ogu, Umuihi & Ihinna is an association of age
          grade originated from Umuihi Ihitte/Uboma Local government Area.
        </p>
        <p className="text-center">
          These are indegenous sons of Umuihi/Ihinna born within the year of
          1975 to 1976 and has undergo the traditional passage rite of Iwa Akwa.
        </p>
      </div>
      <div className="flex mt-5">
        <h1 className="mx-auto font-medium"> This Site is still under construction</h1>
      </div>
    </div>
  );
}
